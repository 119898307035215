<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class="mt-2 mb-2"
          variant="primary"
          @click.stop="dialog = true"
        >
          Adicionar valor manualmente
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      v-model="dialog"
      cancel-variant="outline-secondary"
      centered
      title="Adicionar valor manualmente"
    >
      <b-alert
        v-height-fade.appear
        variant="danger"
        :show="!min"
        class="mb-2"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          Você precisa inserir um valor mínimo de R$ 0,50 para continuar.
        </div>
      </b-alert>

      <b-input-group
        prepend="R$"
      >
        <b-form-input
          v-model="value"
          type="number"
          placeholder="100"
        />
      </b-input-group>

      <template #modal-footer>
        <b-button
          variant="relief-dark"
          class="float-right"
          @click.stop="dialog = false"
        >
          Fechar
        </b-button>
        <b-button
          variant="relief-primary"
          class="float-right"
          :disabled="!valid"
          @click.stop="
            $router.push({
              name: 'Mercado Pago Pix (Checkout)',
              params: { value: value },
            })
          "
        >
          Continuar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BModal,
  BRow,
  BCol,
  BInputGroup,
  BFormInput
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
    BButton,
    BModal,
    BRow,
    BCol,
    BInputGroup,
    BFormInput
  },
  directives: {
    Ripple
  },
  data: () => ({
    dialog: false,
    value: 0
  }),
  computed: {
    min () {
      return this.value >= 0.50
    },
    valid () {
      return this.min
    }
  },
  mounted () {
    this.value = (0.50).toFixed(2)
  }
}
</script>
